/* custom styles */

/* @group reset */
* {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  max-width: 100%;
}
html {
  /* overflow-y: scroll; */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
pre {
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 24px;
  max-width: 100%;
  overflow: auto;
  padding: 12px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
blockquote,
q {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
blockquote {
  color: #767676;
  font-size: 19px;
  font-style: italic;
  font-weight: 300;
  line-height: 1.2631578947;
  margin-bottom: 24px;
}
blockquote cite,
blockquote small {
  color: #2b2b2b;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
blockquote em,
blockquote i,
blockquote cite {
  font-style: normal;
}
blockquote strong,
blockquote b {
  font-weight: 400;
}
button,
input,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
}
button,
input {
  line-height: normal;
  margin-top: 0;
  margin-bottom: 4px;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}
input[type="search"] {
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
hr {
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  height: 1px;
  margin-bottom: 23px;
}
ol,
ul,
li,
dl,
dd {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
em {
  font-style: italic;
}

/* @group LAYOUT */

html {
  font-family: "Sarabun", "Sarabun Regular", "Geneva", sans-serif;
  font-weight: 400;
  color: #555;
}

body {
  background: #fff !important;
  font-size: 15px;
  line-height: 20px;
  overflow-x: hidden;
}

.main,
footer,
nav ul {
  position: relative;
  width: 96%;
  margin: 0 auto;
  padding: 0;
}

.main {
  position: relative;
  padding-bottom: 1px;
  padding-top: 1px;
  margin: 1em auto 3em;
}

header .main {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

@media screen and (max-width: 750px) {
  .flex {
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}

/* @group TYPOGRAPHY */

a {
  text-decoration: underline;
  color: rgba(10, 18, 82, 0.8);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

a:visited {
  text-decoration: underline;
  color: #111;
}

a:hover {
  text-decoration: none;
  color: #333;
}

h1 {
  font-size: 3.9em;
  line-height: 1.2em;
  margin: 1em 0em 0.6em 0em;
}

h1.category {
  font-size: 2.2em;
  text-transform: uppercase;
  font-weight: normal;
  margin: 1em 0em 0.6em 0em;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;
}

h2 {
  font-size: 3em;
  line-height: 1.3em;
  font-weight: normal;
}

h2 a:link,
h2 a:visited {
  text-decoration: none;
  font-weight: normal;
}

h2 a:hover {
  text-decoration: underline;
}

h3 {
  /*section titles*/
  font-size: 1.3em;
  margin: 1.2em 0 0.2em 0;
  font-weight: 600;
}

h4 {
  /*modal titles*/
  color: #111;
  font-size: 1.3em;
  margin: 0.2em 0 0.2em 0;
  font-weight: 600;
}

h4 strong {
  font-size: 0.8rem;
}

h5 {
  /*modal sections*/
  font-size: 1.1em;
  margin: 1.2em 0 1.2em 0;
  font-weight: 600;
  padding-top: 10px;
  padding-left: 1rem;
  padding-bottom: 14px;
  color: #111;
  margin-left: -1rem;
  margin-right: -1rem;
  background: #ededed;
}

h6 {
  font-size: 1.6em;
  margin: 0.4em 0em 0.4em 0em;
  text-transform: uppercase;
}

img {
  border: 0;
}

ol,
ul,
dl {
  margin: 1.5em 1em 1.5em 1.8em;
}

ul > li,
ol > li {
  margin: 0.5em 0;
}

p,
blockquote {
  font-size: 1em;
  line-height: 1.6em;
  margin: 1.2em 0em 1.2em 0em;
}

strong,
b {
  font-weight: bold;
}

a {
  color: #0a1352;
}

/* @group Header */

header {
  width: 100%;
  position: relative;
  background-color: #0a1352;
  margin: 0;
}

header .main {
  background: url(./assets/logo_bg.svg) left top no-repeat #0a1352;
  padding: 1em 0;
  margin: 0 auto;
}

.profile {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.profile button.dropdown,
header a.dropdown {
  color: #fff;
  background: url(./assets/images/icon_down.svg) right center no-repeat !important;
  padding-right: 21px;
  text-decoration: none;
  font-size: 1.2em !important;
  margin: 0 19px 0 0;
  padding: 0 18px 0 0;
  font-weight: 600;
  font-style: normal !important;
}

.profile:hover {
  cursor: pointer;
}

.profile:hover button {
  color: #eee !important;
  text-decoration: underline;
}

.profile > img {
  border-radius: 50% 50%;
}

.logo img {
  display: block;
  width: 100%;
  max-width: 240px;
  height: auto;
  -webkit-filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.99));
  filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.99));
}

.profile ul {
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  float: left;
  margin: 0;
  padding: 0.8em 1.5em 3em;
  position: absolute;
  line-height: 1em;
  top: 90px;
  list-style: none;
  right: 0;
  width: 200px;
  z-index: 9;
  display: block;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  transition: all 0.3s;
  background-image: url(./assets/images/boxes.png);
  background-repeat: no-repeat;
  background-position: right 90%;
  background-size: 40px 14px;
}

.profile ul li {
  margin: 0.9em 0;
}

.profile ul li.small {
  margin: 1.6em 0 1em;
  font-size: 0.8rem;
  line-height: 1.1rem;
  color: #888;
}

.profile ul li.small a:link,
.profile ul li.small a:visited {
  color: #888;
  text-decoration: none;
}

.profile ul li.small a:hover {
  text-decoration: underline;
  color: #111;
}

.profile:hover > ul {
  display: block;
  opacity: 1;
  z-index: 100;
  visibility: visible;
}

/* storage|projects */

header ul.main_toggle {
  list-style: none;
  padding: 0 80px;
  margin: 0;
  display: flex;
}

header ul.main_toggle li {
  position: relative;
  display: block;
}

header ul.main_toggle li a:link,
header ul.main_toggle li a:visited {
  width: 150px;
  font-weight: 400;
  display: block;
  padding-right: 40px;
  padding-bottom: 5px;
  color: #b3b3b3;
  border-bottom: 3px solid #b3b3b3;
}

header ul.main_toggle li a:hover {
  font-weight: 400;
  color: #fff;
  border-bottom-color: #fff;
}

header ul.main_toggle li.selected > a {
  color: #fff;
  border-bottom: 3px solid #52abff;
}

header ul.main_toggle ul {
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  float: left;
  margin: 0;
  padding: 0;
  position: absolute;
  line-height: 2em;
  top: 35px;
  list-style: none;
  left: 0;
  z-index: 9;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

header ul.main_toggle ul,
header ul.main_toggle li ul a:link,
header ul.main_toggle li ul a:visited {
  width: 220px;
}

header ul.main_toggle ul li {
  height: auto;
  padding: 0;
  margin: 0;
}

header ul.main_toggle li ul a:link,
header ul.main_toggle li ul a:visited {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  color: #333;
  font-size: 0.9rem;
  height: auto;
  line-height: 1.2em;
  padding: 10px 20px 12px 10px !important;
  text-decoration: none;
  margin: 0;
  text-align: left;
}

/* header ul.main_toggle li ul a:hover {
  background: #fff;
  color: #0f6fc9;
  text-decoration: underline;
}

header ul.main_toggle li ul li:last-child a:link,
header ul.main_toggle li ul li:last-child a:visited {
  color: #fff;
  background: #52abff;
} */

header ul.main_toggle li ul li:first-child a:hover {
	color: #fff;
	background: #52ABFF;
}

header ul.main_toggle li>ul:hover>li:last-child a {
	background-color: #fff;
	color: #333;
}

header ul.main_toggle li>ul>li:last-child a:hover {
	color: #fff;
	background: #52ABFF;
}

header ul.main_toggle li ul li:last-child a {
	color: #fff;
	background: #52ABFF;
}

header ul.main_toggle li:hover > ul {
  display: block;
  opacity: 1;
  visibility: visible;
}

/* @group Content */

.content {
  width: 96%;
  margin: 2em auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.content .column {
  flex: 1 0 48%;
  margin: 0 1% 0 0;
}

.boxed_section {
  border: 1px solid #eee;
  background: #fff;
  padding: 1em 1.5em;
  margin: 2% 0;
  border-radius: 10px;
  box-shadow: 0 0 6px #eee;
  width: 100%;
}


.boxed_section.clear {
  background: none;
}

.boxed_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
}

.boxed_header h3 {
  margin: 0.5em 0;
  flex: 1 1 auto;
}

.boxed_header form.advanced_search {
  text-align: right;
}

.boxed_header form.advanced_search input {
  width: 200px;
}

a.button {
  padding: 10px 18px;
  background: #999;
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  font-size: 0.8em;
  line-height: 0.8em;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin-left: 0.4em;
}

h3 a.button {
  font-size: 0.7rem;
}

td a.button {
  width: 100%;
  text-align: center;
}

a.button:hover {
  background-color: #0a1352 !important;
}

a.button.processing {
  background-color: #61b261;
}

a.button.error {
  background-color: #7c20ac;
}

a.button.interface {
  background-color: #52abff;
}

.content_block button {
  font-size: 0.8em;
  position: relative;
  margin: 0.4em 0;
  padding: 0;
  line-height: 1em;
  display: inline-block;
  vertical-align: middle;
  color: #111;
  text-decoration: none;
  padding: 8px 16px 10px 16px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  /* text-shadow: 1px 1px 1px #b6390c; */
  text-shadow: 1px 1px 1px #fff;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
  -moz-border-radius: 16px;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  /* background-color: #f15a24; */
  background-color: #eee;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.content_block button:hover {
  text-decoration: none;
  color: #fff !important;
  text-shadow: 1px 1px 1px #333;
  background: #0a1352;
}

/* collaborators */

.collaborators {
  display: flex;
  flex-flow: row wrap;
}

.collaborator {
  display: flex;
  flex: 1 1 50%;
  align-items: center;
  margin: 0.5em 0 0;
}

.collaborator p {
  margin: 0 0 0.7em;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 1.1rem;
}

.collaborator p strong {
  display: block;
  text-transform: none;
  font-size: 1rem;
}

.collaborator p a {
  text-decoration: none;
}

.collaborator img,
div.avatar {
  width: 60px;
  height: 60px;
  display: block;
  background: #ccc;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 50% 50%;
  margin-right: 10px;
  text-decoration: none;
  cursor: pointer;
}

div.avatar em {
  text-align: center;
  flex: auto;
  margin-bottom: 3px;
  font-style: normal;
}

/* toggle button */

.button_toggle {
  position: relative;
  width: 74px;
  height: 36px;
  margin: 10px 0;
  border-radius: 2px;
  overflow: visible;
}

.button_toggle.r,
.button_toggle.r .layer {
  border-radius: 100px;
}

.button_toggle * {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.button_toggle:focus {
  outline: none;
}

.button_toggle .knobs,
.button_toggle .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button_toggle .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.button_toggle .knobs {
  z-index: 2;
  perspective: 70px;
}

.button_toggle .layer {
  width: 100%;
  background-color: #fcebeb;
  transition: 0.3s ease all;
  z-index: 1;
}

.knobs:before,
.knobs:after,
.knobs span {
  position: absolute;
  top: 4px;
  border-radius: 2px;
}

.knobs:before,
.knobs:after {
  width: 20px;
  height: 10px;
  color: #4e4e4e;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 7px 4px;
}

.knobs:before {
  content: "NO";
  left: 4px;
}

.knobs:after {
  content: "YES";
  right: 4px;
}

.knobs span {
  right: 4px;
  width: 33px;
  height: 28px;
  background-color: #f44336;
  transform: rotateY(0);
  transform-origin: 0% 50%;
  transition: 0.6s ease all;
  z-index: 1;
}

.button_toggle .checkbox:checked + .knobs span {
  transform: rotateY(-180deg);
  background-color: #51abff;
}

.button_toggle .checkbox:checked ~ .layer {
  background-color: rgba(82, 171, 255, 0.3);
}

/* @group Table */

/* table p {
	font-size: .9em;
	color: #777;
	line-height: 1.6em;
  margin: 0;}

.dataTables_wrapper {
	background: #ffffff;
	position: relative;
	margin: 0 auto;
	padding: 2px 0px 0px;
	*zoom: 1;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row wrap;
	flex-flow: row wrap; }

table.dataTable {
	margin: 20px auto 40px;
	width: 100%;}

	/* table.dataTable td:nth-of-type(2n) a:link,
	table.dataTable td:nth-of-type(2n) a:visited {
		font-size: .9rem;
		line-height: .9rem;
		display: inline-block;
		text-decoration: none;
		color: #444;
		background-color: #f2f2f2;
		border: 1px solid #ccc;
		border-radius: 4px;
		padding: 1px 7px 4px;
		margin-top: 0;
		margin-bottom: 4px; 	} */

/* table.dataTable td:nth-of-type(2n) a:hover {
		background-color: rgba(0, 0, 0, 0.05);
		color: #0A1352;	} */

/* table.dataTable td:nth-of-type(2n) a.all {
		border: none;
		font-style: italic;
		text-decoration: underline;	} */

table.dataTable thead th {
  padding: 10px 8px 10px 21px;
  border-bottom: 1px solid #ccc;
  /* border-top: 1px solid #ccc; */
  cursor: pointer;
  text-align: left;
  text-transform: uppercase;
  color: #999;
  font-size: 0.8rem;
}

table.dataTable tr th:nth-child(3n) {
  white-space: nowrap;
}

table.dataTable tfoot th {
  padding: 3px 18px 3px 10px;
  border-top: 1px solid black;
  font-weight: bold;
}

table.dataTable td {
  padding: 14px 12px 14px 14px;
}

table.dataTable td a.highlight {
  font-size: 1.1em;
  font-weight: bold;
  text-decoration: none;
}

table.dataTable td a.highlight:hover {
  text-decoration: underline;
}

table.dataTable td.center,
table.dataTable td.dataTables_empty {
  text-align: center;
}

/* table.dataTable tr.odd { background-color: rgba(0, 0, 0, 0.05); }
table.dataTable tr.even { background-color: white; } */

/* 
table.dataTable tr.odd td.sorting_1 { background-color: rgba(204, 204, 204, 0.4); }
table.dataTable tr.odd td.sorting_2 { background-color: rgba(204, 204, 204, 0.3); }
table.dataTable tr.odd td.sorting_3 { background-color: rgba(204, 204, 204, 0.2); }
table.dataTable tr.even td.sorting_1 { background-color: rgba(204, 204, 204, 0.4);}
table.dataTable tr.even td.sorting_2 { background-color: rgba(204, 204, 204, 0.3); }
table.dataTable tr.even td.sorting_3 { background-color: rgba(204, 204, 204, 0.2); }
 */

/* * Page length menu*/
.dataTables_length {
  flex: 1 1 auto;
  text-align: right;
}

/** Filter */
.dataTables_filter {
  flex: 1 1 auto;
  order: -1;
  text-align: left;
}

.dataTables_filter input {
  margin-left: 3px;
}


/* * Table information */
.dataTables_info {
  clear: both;
  float: left;
}

/* * Pagination */
.dataTables_paginate {
  float: right;
  text-align: right;
}

/* Two button pagination - previous / next */
.paginate_disabled_previous,
.paginate_enabled_previous,
.paginate_disabled_next,
.paginate_enabled_next {
  height: 19px;
  float: left;
  cursor: pointer;
  color: #111 !important;
}

.paginate_disabled_previous:hover,
.paginate_enabled_previous:hover,
.paginate_disabled_next:hover,
.paginate_enabled_next:hover {
  text-decoration: none !important;
}

.paginate_disabled_previous:active,
.paginate_enabled_previous:active,
.paginate_disabled_next:active,
.paginate_enabled_next:active {
  outline: none;
}

.paginate_disabled_previous,
.paginate_disabled_next {
  color: #666 !important;
  text-decoration: none;
}

.paginate_disabled_previous,
.paginate_enabled_previous {
  padding-left: 23px;
}

.paginate_disabled_next,
.paginate_enabled_next {
  padding-right: 23px;
  margin-left: 10px;
}

.paginate_enabled_previous {
  background: url("./assets/images/back_enabled.svg") no-repeat top left;
}
.paginate_enabled_previous:hover {
  background: url("./assets/images/back_enabled_hover.svg") no-repeat top left;
}
.paginate_disabled_previous {
  background: url("./assets/images/back_disabled.svg") no-repeat top left;
}

.paginate_enabled_next {
  background: url("./assets/images/forward_enabled.svg") no-repeat top right;
}
.paginate_enabled_next:hover {
  background: url("./assets/images/forward_enabled_hover.svg") no-repeat top
    right;
}
.paginate_disabled_next {
  background: url("./assets/images/forward_disabled.svg") no-repeat top right;
}

/* Full number pagination */
.paging_full_numbers {
  height: 22px;
  line-height: 22px;
}

.paging_full_numbers a:active {
  outline: none;
}

.paging_full_numbers a:hover {
  text-decoration: none;
}

.paging_full_numbers a.paginate_button,
.paging_full_numbers a.paginate_active {
  border: 1px solid #aaa;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 2px 5px;
  margin: 0 3px;
  cursor: pointer;
  color: #333 !important;
}

.paging_full_numbers a.paginate_button {
  background-color: #ddd;
}

.paging_full_numbers a.paginate_button:hover {
  background-color: #ccc;
  text-decoration: none !important;
}

.paging_full_numbers a.paginate_active {
  background-color: #999;
}

/* * Processing indicator */
.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 30px;
  margin-left: -125px;
  margin-top: -15px;
  padding: 14px 0 2px 0;
  border: 1px solid #ddd;
  text-align: center;
  color: #999;
  font-size: 14px;
  background-color: white;
}

.sorting_asc,
.sorting_desc {
  color: #0a1352 !important;
  /* font-weight: 600; */
}

/* * Sorting */
.sorting {
  background: url("./assets/images/sort_both.svg") no-repeat center left;
}
.sorting_asc {
  background: url("./assets/images/sort_asc.svg") no-repeat center left;
}
.sorting_desc {
  background: url("./assets/images/sort_desc.svg") no-repeat center left;
}

.sorting_asc_disabled {
  background: url("./assets/images/sort_asc_disabled.png") no-repeat center left;
}
.sorting_desc_disabled {
  background: url("./assets/images/sort_desc_disabled.png") no-repeat center
    left;
}

table.dataTable thead th:active,
table.dataTable thead td:active {
  outline: none;
}

/* * Scrolling */
.dataTables_scroll {
  clear: both;
}

.dataTables_scrollBody {
  margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}

a.btn {
  font-size: 1em;
  line-height: 1em;
  position: relative;
  margin: 1.5em 0.4em 0.5em;
  padding: 0;
}

a.btn.centered {
  display: block;
  text-align: center;
}

a.btn:link,
a.btn:visited {
  color: #fff;
  text-decoration: none;
  padding: 3px 10px 6px;
  margin: 0;
  display: inline-block;
  border: 1px solid #333;
}

a.btn:hover {
  text-decoration: none;
  color: #eee;
  background: #ab160c;
}

a.btn:hover .icon:before {
  color: #7ebc53;
}



.card {
  position: absolute;
  padding: 10px;
  border: 1px solid #333;
}

/* @group Table Navigation Icons */

.dataTables_filter,
.dataTables_length {
  display: none;
}

.table_nav {
  padding: 0;
  margin: 1em 0 6px;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.table_nav ul:after {
  content: url(./assets/images/icon_divider.svg);
  margin-left: 12px;
  margin-right: 4px;
}

.table_nav ul:last-of-type:after {
  content: "";
}

.table_nav ul {
  padding: 0;
  margin: 1em 0 0;
  list-style: none;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.table_nav ul li {
  margin: 5px 0;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
}

.table_nav ul li a:link,
.table_nav ul li a:visited {
  color: #555;
  text-decoration: none;
  padding: 5px 10px;
  display: block;
}

@media screen and (max-width: 800px) {
  .table_nav {
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}

/* @end */

/* @group Buttons */

button.btn {
  background: none;
  font-size: 1em;
  line-height: 1.4em;
  font-style: italic;
  color: #777;
  align-items: center;
  /* padding: 0 5px 5px; */
  display: flex;
  text-align: center;
  text-transform: lowercase;
  border: none;
  margin: 0 2px;
}

.modal button.btn {
  font-size: 1em;
  line-height: 1.1em;
  color: #fff;
  border: 1px solid #333;
  padding: 8px;
  background-color: #0a1352;
  text-transform: capitalize;
}

button:hover {
  color: #f15923;
}

button.btn#icon_upload:hover {
  color: #51abff;
}

button.btn:hover {
  color: #f15923;
}

button.btn:before {
  display: block;
  -webkit-transition: all 0.05s;
  -moz-transition: all 0.05s;
  -o-transition: all 0.05s;
  -ms-transition: all 0.05s;
  transition: all 0.05s;
}

#icon_upload:before {
  content: url(./assets/images/icon_upload.svg);
}

#icon_upload:hover:before {
  filter: invert(0.4) sepia(1) saturate(15) hue-rotate(180deg) brightness(1);
}

button.btn:hover:before {
  filter: grayscale(99%) brightness(191%);
  filter: invert(0.4) sepia(1) saturate(20) hue-rotate(334.8deg) brightness(1);
}

#icon_newfolder:before {
  content: url(./assets/images/icon_newfolder.svg);
}

#icon_addtoproject:before {
  content: url(./assets/images/icon_addtoproject.svg);
}

/***/

#icon_rename:before {
  content: url(./assets/images/icon_edit.svg);
}

#icon_movecopy:before {
  content: url(./assets/images/icon_movecopy.svg);
}

#icon_color:before {
  content: url(./assets/images/icon_color.svg);
}

#icon_tags:before {
  content: url(./assets/images/icon_tags.svg);
}

/***/

#icon_share:before {
  content: url(./assets/images/icon_share.svg);
}

#icon_transfer:before {
  content: url(./assets/images/icon_transfer.svg);
}

#icon_download:before {
  content: url(./assets/images/icon_download.svg);
}

#icon_trash:before {
  content: url(./assets/images/icon_trash.svg);
}

/** table info icons **/

.tooltip {
  display: inline;
  position: relative;
  margin: 0;
  padding: 4px;
}

a.tooltip {
  text-decoration: none;
}

.tooltip:hover:before {
  filter: grayscale(99%) brightness(191%);
  filter: invert(0.4) sepia(1) saturate(20) hue-rotate(334.8deg) brightness(1);
}

.tooltip[title]:hover:after {
  font-size: 0.8em;
  line-height: 1.2em;
  background: rgba(10, 19, 82, 0.8);
  border-radius: 5px;
  bottom: 26px;
  color: #fff;
  content: attr(title);
  left: 0;
  padding: 5px 7px 6px;
  position: absolute;
  white-space: normal;
  width: auto;
  z-index: 9;
}

.tooltip.info_icons:hover:after {
  font-size: 0.8em;
  line-height: 1.2em;
  bottom: 26px;
  color: #fff;
  content: attr(title);
  left: -55%;
  padding: 5px 7px 6px;
  position: absolute;
  white-space: normal;
  width: auto;
  z-index: 9;
}

#icon_table_share:before {
  content: url(./assets/images/icon_table_share.svg);
}

#icon_table_locked:before {
  content: url(./assets/images/icon_table_locked.svg);
}

#icon_table_folder:before {
  content: url(./assets/images/icon_table_folder.svg);
}



body.modal-open {
  padding: 0 !important;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  overflow: hidden;
  outline: 0;
  padding: 0 !important;
  margin: 0 !important;
}

/* .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
} */

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 0 0.5em 0.2em;
  margin: -1rem -1rem -1rem auto;
  order: 2;
  border: none;
  color: #f15923;
  font-size: 1.5em;
  line-height: 1.7em;
  background: #fff;
  border-left: 1px solid #f15923;
  border-bottom: 1px solid #f15923;
  font-weight: 600;
}
.close-x {
  order: 2;
  padding: 5px 10px;
  color: #f15923;
  background: #fff;
  border: 1px solid #f15923;
}

.modal-header .close:hover {
  color: #111;
  border-left: 1px solid #111;
  border-bottom: 1px solid #111;
}

.modal-header .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  /* padding: 0px; */
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100vw;
    margin: 1.75rem auto;
  }
  .modal-90w {
    max-width: 90%;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 600px;
  }
}

/*@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }}*/

/* @end */

/* @group NAVIGATION */

nav {
  background: #fff;
  -moz-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

#toggle-bar {
  display: none;
}

nav ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
}

nav ul li {
  font-size: 1.1em;
  line-height: 0.5em;
  margin: 0;
  padding: 0;
  position: relative;
}

nav ul li a:link,
nav ul li a:visited {
  color: #0a1352;
  display: block;
  padding: 18px 20px 24px 20px;
  text-decoration: none;
  margin: 0;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

nav ul li.selected a,
nav ul li.selected a:hover {
  font-weight: 400;
  background: #52abff;
  color: #fff;
}

nav ul li:hover > a,
nav ul ul:hover > a,
nav ul a:focus {
  background: #0a1352;
  background: rgba(82, 171, 255, 0.2);
  color: #0a1352;
}

nav ul ul {
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  float: left;
  margin: 0;
  position: absolute;
  line-height: 1em;
  top: 80px;
  list-style: none;
  left: 0;
  width: 188px;
  z-index: 9;
  display: block;
  opacity: 0;
  visibility: hidden;
}

nav ul ul li {
  height: auto;
  padding: 0;
  margin: 0;
}

nav ul ul a {
  background-color: #fff;
  color: #333;
  font-size: 1rem;
  height: auto;
  line-height: 1.2em;
  padding: 10px 20px 12px !important;
  width: 188px;
  margin: 0;
  text-align: left;
}

nav ul ul a:hover {
  background: #eee;
  color: #0f6fc9;
  text-decoration: none;
}

nav ul li:hover > ul {
  display: block;
  opacity: 1;
  visibility: visible;
}

/* @end */
@media screen and (max-width: 535px) {
  .profile > img {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  nav {
    height: 60px;
    position: relative;
  }

  #toggle-bar {
    position: absolute;
    top: 20px;
    display: block;
    z-index: 100;
  }

  #toggle-bar a {
    color: #fff;
    padding: 15px 10px 15px 30px;
    margin: 0;
  }

  #toggle-bar a:focus,
  #toggle-bar a:visited,
  #toggle-bar a:active {
    outline: none;
    box-shadow: none;
  }

  nav ul.responsive {
    margin: 60px 0 0;
    padding: 0;
    background: #fff;
    list-style: none;
    position: absolute;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    -webkit-flex-direction: column;
  }

  nav ul li {
    display: none;
  }

  nav ul.responsive li {
    font-size: 1rem;
    z-index: 100 !important;
    position: relative;
    display: block !important;
    width: 100% !important;
    height: auto;
    padding: 0;
    -moz-box-shadow: 0 8px 9px rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 9px rgba(0, 0, 0, 0.2);
  }

  nav ul.responsive li a {
    display: block !important;
    width: 100% !important;
    padding: 10px 5px 10px 30px;
    margin: 0;
    background-color: #f8f8f8;
  }
  nav ul.responsive li.selected a,
  nav ul.responsive li.selected a:hover {
    font-weight: 400;
    background: #52abff;
    color: #fff;
  }
  nav ul.responsive ul {
    position: relative;
    top: auto;
    width: 100%;
    opacity: 100;
    visibility: visible;
    margin-top: 0;
    margin-bottom: 0;
  }

  nav ul.responsive li ul li a {
    padding: 7px 0 10px 58px !important;
  }
}

/* @group FOOTER */

footer {
  color: #ccc;
}

/* @end */

/* @group Forms, Etc. */

input[type="text"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="url"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  border: 1px solid #cacaca;
  color: #3c372d;
  font-size: 1rem !important;
  line-height: 1.5em !important;
  padding: 5px 12px;
  display: inline-block;
  width: 100%;
}

input[type="checkbox"] {
  appearance: normal;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  border: 1px solid #cacaca;
  color: #3c372d;
  font-size: 1rem !important;
  line-height: 1.5em !important;
  padding: 5px 12px;
  display: inline-block;
  width: 100%;
  background-image: url(./assets/images/select-bg.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  -webkit-background-size: 12px 10px;
  background-size: 12px 10px;
  margin: 2px 0 18px;
  display: block;
}

form.category.flex {
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

form.category .select {
  flex: 1 1 50%;
  margin-left: 1em;
}

.dataTables_wrapper select {
  /*show 10 entries*/
  padding-right: 20px !important;
}

select.multiselect {
  background: none;
}

input:focus,
textarea:focus,
select:focus {
  color: #2e7ac2;
  border-color: #adadad;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06),
    0 0 5px rgba(160, 160, 160, 0.7);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06),
    0 0 5px rgba(160, 160, 160, 0.7);
  outline: none;
}

/* form label {
	display: block;
	margin: 0 0 7px; } */

/*layout - share popup, search above table*/

form.column input,
form.column select {
  display: inline-block;
}

form.column.three input {
  width: 35%;
}

form.column.three select {
  width: 26%;
  margin-bottom: 6px;
}

/*advanced search above table*/

form.advanced_search {
  margin: 1em 0 0;
}

form.advanced_search input {
  display: inline-block;
  vertical-align: top;
  width: 60%;
}

form.advanced_search button {
  background: #f15923;
  padding: 6px 10px 9px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #fff;
  margin: -1px 0 0;
}

form.advanced_search button + button {
  background: #555;
}

/*advanced search popup*/

form.column.two label {
  display: inline-block;
  text-align: right;
  width: 20%;
  padding-right: 10px;
}

form.column.two input,
form.column.two select {
  width: 68%;
}

/* @end */

.loader {
  height: 70vh;
}

/* Inline loader */
.relative-loader {
  display: flex;
  justify-content: center;
}

.file::before {
  content: "";
  border-left: 5px solid var(--color);
  margin-right: 5px;
}
.rdt_TableRow:hover .file::before {
  content: "";
  border-left: 5px solid var(--hover-color);
  margin-right: var(--margin);
}

.tag {
  font-size: 0.9rem;
  line-height: 0.9rem;
  display: inline-block;
  text-decoration: none;
  color: #444;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1px 7px 4px;
  margin-top: 0;
  margin-bottom: 4px;
  margin-right: 2px;
}

.modal-90w {
  width: 90% !important;
}
.modal-98w {
  max-width: 98% !important;
}

/* TAGS */

.exp-content {
  margin-top: 10px;
  padding: 20px;
}

.file-list {
  display: flex;
  justify-content: space-between;
  width: 550px;
}

/* accordian + tabs */

.accordion {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.panel {
  background-color: #eeeeee;
  border-radius: 8px;
}
.panel__label {
  position: relative;
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 25px 60px 25px 25px;
  font-weight: 500;
  font-size: 17px;
  font-family: inherit;
  transition: color 0.2s linear;
  cursor: pointer;
}
.panel__label:focus {
  outline: none;
}
/* .panel__label:after,
.panel__label:before {
  content: '';
  position: absolute;
  right: 25px;
  top: 50%;
  width: 22px;
  height: 2px;
  margin-top: -2px;
  background-color: #372717;
}
.panel__label:before {
  transform: rotate(-90deg);
  transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
} */
.panel[aria-expanded="true"] .panel__content {
  opacity: 1;
}
.panel[aria-expanded="true"] .panel__label {
  color: #957029;
}
.panel[aria-expanded="true"] .panel__label:before {
  transform: rotate(0deg);
}
.panel__inner {
  overflow: hidden;
  will-change: height;
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.panel__content {
  margin: 5px 25px 25px;
  font-size: 14px;
  color: #756658;
  opacity: 0;
  transition: opacity 0.3s linear 0.18s;
}
.panel:not(:last-child) {
  margin-bottom: 3px;
}

/* Tabs */

.tabs {
  white-space: nowrap;
  position: relative;
  display: flex;
  list-style: none;
  padding: 0px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  color: #757575;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}
.tabs .tab__item {
  padding: 12px;
  cursor: pointer;
}
.tabs .tab__item.active {
  color: #1976d2;
}
.tabs .indicator {
  height: 2px;
  background-color: #1976d2;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transition: left 500ms, width 500ms;
}
.tab__content {
  display: none;
  margin-bottom: 48px;
  padding: 22px;
}
.tab__content.active {
  display: block;
}

.dropdown__wrapper {
  margin-left: auto;
  margin-right: 12px;
}
.dropdown__wrapper .dropdown__toggle {
  font-size: 26px;
  text-decoration: none;
}
.dropdown__wrapper .dropdown {
  list-style: none;
  padding: 0px;
  background: white;
  position: absolute;
  top: 100%;
  right: 0px;
  display: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.dropdown__wrapper .dropdown.active {
  display: block;
}
.dropdown__wrapper .dropdown li {
  padding: 12px;
  cursor: pointer;
}
.dropdown__wrapper .dropdown li.active {
  border-bottom: 2px solid #1976d2;
  color: #1976d2;
}

.files {
  flex: 1;
  overflow: auto;
  display: flex;
  color: black;
  border-radius: 2px;
}
.content-area {
  display: flex;
}
.files div div div div {
  background: lightgray;
  border-radius: 8px;
}
.files div div div {
  overflow-wrap: break-word;
  border-radius: 2px;
  background-color: transparent;
}
section.list {
  background: lightgray;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}
.dimension-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #d6e7f7;
  width: 100%;
}
section.list .dimension {
  color: #295684;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;

  margin: 0;
  padding: 2px;
  padding-left: 8px;
}

.filter {
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 12px;
}
.filter input {
  font-size: 12px !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.variable-action {
  margin: 0.5em 10px 0.5em auto;
  padding: 12px;
}

.variable-action svg {
  cursor: pointer;
}
.variable-action .fa-trash {
  margin-right: 10px;
  color: orangered;
}
.variable-action .fa-plus {
  color: #52abff;
}

.add-dimension {
  font-size: 20px;
  margin: 0;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.files::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.files::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

.files::-webkit-scrollbar-thumb {
  background-color: #0ae;

  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}

.remove-dimension {
  padding: 0px;
}

.remove-dimension svg {
  cursor: pointer;
}
.remove-dimension .fa-trash {
  margin-right: 10px;
  color: orangered;
  display: none;
}

.dimension-header:hover .fa-trash {
  display: block;
}

.label {
  display: block;
}
.overview-box{
	border: 1px solid #cacaca;
	padding: 8px;
	width: 100%;
	margin: 10px 0 0 0;
	resize: none;
}

.tooltip-main {
  z-index: 10000;
  position: absolute;
  inset: 0px auto auto 10px;
  transform: translate(514px, 345px);
  background-color: whitesmoke;
  border-radius: 5px;
  padding: 8px;
  width: 700px;
}

.table > .admin-table-head th:last-child {
  width: 90px;
  /* cursor: pointer; */
}

.table > .admin-table-body td:last-child .trash-icon {
  cursor: pointer;
}

.table > .admin-table-body td:last-child button {
  /* background-color: transparent !important; */
  font-size: 12px;
  text-align: center;
  margin-left: 14px;
  cursor: pointer;
}

.edit-access {
  margin: 0;
  padding-bottom: 8px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  color: #333333;
}

.admin-user-table > .add > .add-btn {
  font-size: 1em;
  line-height: 1.1em;
  color: #fff;
  border: 1px solid #333;
  padding: 8px;
  background-color: #0a1352;
  text-transform: capitalize;
}
.text-center{
  display: flex;
  justify-content: center;
}
.add > .add-btn,
.text-center > .add-btn,
.add > .admin-organisation-container .add-btn,
.add > .admin-division-container .add-btn{
  font-size: 1em;
  line-height: 1.1em;
  color: #fff;
  border: 1px solid #333;
  padding: 8px;
  background-color: #0a1352;
  text-transform: capitalize;
}
.adduserbutton{
  display: flex;
    justify-content: flex-end;
    /* width: 46%; */
    text-align: center;
    align-items: center;
    margin: 7px 0px 0px 0px;
}
.add-user-select > select {
  margin: 0px !important;
}
.add-user-select > div > button {
  margin: 2px 0 0px !important;
}

.division-table-body td:last-child > .trash-icon {
  margin-right: 6px;
  cursor: pointer;
}

.add > .admin-organisation-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add > .admin-organisation-container > div:first-child {
  width: 25%;
  margin-left: 30px;
  margin-top: 6px;
}

.add > .admin-organisation-container > div:first-child > label {
  text-align: left !important;
}

.admin-table-container {
  width: 100% !important;
}

.admin-table-container > .admin-user-table-container {
  margin-top: 18px;
  width: 100% !important;
  padding: 0 30px;
}

.add > .text {
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px !important;
  margin-bottom: 14px;
}



.org-select-admin {
  background-color: #e2e2e2;
  cursor: not-allowed;
}

.add > .admin-division-container > .drop label{
  text-align: left !important;
}
.add > .admin-division-container > .divdrop label{
  text-align: left !important;
}
/* .table>:not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: red !important;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
} */
.Toastify__toast-theme--light {
  background: #eee !important;
  color: var(--toastify-text-color-light);
}
/* suhail */
.customDropDown button.btn {
  font-style: normal;
  color: rgb(0, 0, 0);
  text-transform: capitalize;
  background-color: transparent;
  border-color: transparent;
  margin: 2px 0px 0px;
  transition: none !important;
  box-shadow: none!important;
}
.customDropDown button.btn:hover{
  background-color: transparent;
  border-color: transparent;
  transition: none !important;
  box-shadow: none!important;
}
.customDropDown .dropdown-toggle::after{
  display: none;
}
.dropdown, .dropdown-center, .dropend, .dropstart, .dropup, .dropup-center {
  position: relative;
  display: inline-block;

}
/* suhail end */
/* .css-48ayfv */
.css-1r4vtzz,.css-48ayfv{
    width: 100% !important;
    padding: 5px 12px !important;
    background-image: url(./assets/images/select-bg.svg) !important;
    background-repeat: no-repeat !important;
    background-position: right 10px center !important;
    -webkit-background-size: 12px 10px !important;
    background-size: 12px 10px !important;
    border-radius: 3px !important;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 6%) !important;
    border: 1px solid #cacaca !important;
    color: #3c372d !important;
    font-size: 1rem !important;
    line-height: 1.5em !important;
    cursor: pointer !important;
    background-color: #fff !important;
    margin: 2px 0 18px !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    display: inline-block !important;
    display: block !important;
    -moz-appearance: none !important;
    -webkit-border-radius: 3px !important ;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06) !important;

}
.css-1gpjby2 {
  display: none!important;
}

.ia-btn{
  width: 110px;
  justify-content: center !important;
  border-radius: 5px;
}

.div-btn{
  width: 150px;
  justify-content: center !important;
  border-radius: 5px;
}
