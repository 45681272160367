/* .table {
  margin: auto;
  margin-top: 0.5em;
  width: 95%;
} */

.add {
  text-align: right;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  margin-right: 2em;
}
.internaladminadd {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  font-size: 1em;

  /* margin-right: 2em; */
}
.internaladminaddcolor{
  color: #fff !important;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  /* border: 1px solid #333 !important; */
  padding: 8px !important;
  background-color: #0a1352 !important;
  text-transform: capitalize !important;
}
.pull {
  text-align: right;
  margin-right: 2em;
}

.text {
  text-align: left;
  margin-left: 2em;
}

.mod {
  display: flex;
  justify-content : center !important ;
  width: 60%;
  margin: 0 auto !important;
  
  
}
