#loader {
 animation: rotation 3s linear infinite; 
 transform-style: preserve-3d;
 transform-origin: 50% 50%;
}

@keyframes rotation {
  0%{
    transform: rotateY(0deg);
  }
  100%{ transform:rotateY(360deg); }
}