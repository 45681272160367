.org_modal>.modal-dialog {
    display: flex;
    justify-content: center;
}

.org_modal>.modal-dialog>.modal-content {
    width: 85%;
}

.modal_close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.closeSVG {
    display: block !important;
    width: 1.6em;
    height: 2em;
    cursor: pointer;
}

.mfaresetbtn {
    padding: 8px;
    border-radius: 10px;
    background-color: red;
    color: white;
    font-weight: bold;
}

.mfaresetbtn:hover {
    color: white;
}

.org_row:hover {
    cursor: pointer;
    background-color: #0a135261;

}

.org_edit_btn {
    padding: 8px;
    width: 65px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px !important;
}
.org_edit_btn:hover{
    color: black !important;
}

.org_delete_btn {
    padding: 8px;
    width: 85px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px !important;
    background-color: red !important;
    color: white !important;
}