.admin-table-container,
.division-table-container,
.division-user-table-container,
.billing-table-container {
    width: 100% !important;
}

.admin-table-container>.admin-user-table-container,
.division-table-container>.division-list-container,
.division-user-table-container>.division-innerTable-container,
.billing-table-container>.billing-user-table-container {
    margin-top: 18px;
    width: 100% !important;
    padding: 0 30px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header>.header-label {
    font-size: 18px;
    font-weight: 700;
    margin-left: 30px;
}

.header-section {
    margin-right: 30px;
}

.header>.header-section>.add-btn {
    font-size: 1em;
    line-height: 1.1em;
    color: #fff;
    border: 1px solid #333;
    padding: 8px;
    background-color: #0a1352;
    text-transform: capitalize;
}

.user-table>.table-responsive {
    height: 300px;
}


.multi-select-dropdown {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    height: 34px;
}

.multi-select-dropdown_wrapper {
    border: solid 1px #cacaca;
    border-radius: 5px;
    font-size: 14px;
    height: 35px;
    padding: 5px 12px;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.multi-select-dropdown-input {
    width: 245px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-weight: 400;
}

.multi-select-dropdown-direction {
    display: flex;
    cursor: pointer;
}

.multi-select-dropdown_options_wrapper {
    position: relative;
    border-radius: 5px;
    border: 1px solid var(--Grey-200, #E5E7EB);
    background: var(--bw-white, #FFF);
    box-shadow: 0px 2px 5px 0px rgba(55, 65, 81, 0.16);
}

.multi-select-dropdown_options {
    list-style: none;
    padding-left: 0px;
    margin: 0;
    padding: 5px 0px;
    max-height: 250px;
    overflow-y: auto;
}

.multi-select-dropdown_option {
    display: flex;
    /* align-items: flex-start; */
    gap: 6px;
    flex: 1 0 0;
    padding: 6px 10px;
    cursor: pointer;
    color: var(--Grey-800, #1F2937);
    /* Body/B1 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
}

.multi-select-dropdown_option:hover {
    background-color: #eee;
}

.multi-select-dropdown_option-checkbox {
    margin-right: 6px;
    width: 24px;
    height: 24px;
}

.no-hover:hover {
    /* Override default hover styles */
    background-color: red !important;
    color: white !important;
    border-color: inherit !important;
    /* Add any other styles you want to apply on hover */
}

.drop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    margin-bottom: 0 !important;
}

.drop>.organisation-container {
    width: 25% !important;
    margin-right: 16px;
}

.divdrop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.divdrop>.organisation-container {
    width: 25% !important;
    margin-right: 16px;
}

.table {
    padding: 16px 30px;
    padding-top: 1em;
    width: 100%;
}

.user_edit_btn {

    padding: 8px;
    width: 65px;
    height: max-content;
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px !important;
}

.user_edit_btn:hover {
    color: black !important;
}

.user_delete_btn {
    padding: 8px;
    width: 85px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px !important;
    background-color: red !important;
    color: white !important;
}
.userRow:hover {
    cursor: pointer;
    background-color: #0a135261;

}
.billing_division_name{
    font-weight: bold;
    font-size: 20px;
}
.division_list_name >span{
    font-size: 14px !important
}