.rdt_Pagination select{

display: none !important;
	/* background-repeat: no-repeat;
	background-position: right 10px center;
	-webkit-background-size: 12px 10px;
	background-size: 12px 10px;
	margin: 2px 0 18px;
  /* display: block;	 */
}

.rdt_Pagination {
	box-shadow: none;
	justify-content: space-between !important;
}

.rdt_Pagination span:nth-of-type(1), .rdt_Pagination div:nth-of-type(1) {
  display: none;
}
.rdt_TableHeader{
  display: none !important;
}

.rdt_TableHeadRow * {
	font-size: 16px;
}

.rdt_TableRow:hover{
	background-color: rgba(82, 171, 255, 0.3) !important;
}
.sr-only{
	display: none !important;
}

.modal-body {
	margin-top: 5px;
	width: 100% !important;
	padding: 1rem;
}

.project-name{
	font-weight:200;
	font-size: 16px;
}

.project-name a{
	text-decoration: none;
}