.wizard-App {
    text-align: center;
    font-family: Arial, sans-serif;
    padding-bottom: 40px;
    padding: 20px; /* Added padding for smaller screens */
  }
  
  .wizard-Heading {
    font-size: xx-large;
  }
  
  .wizard-Headings {
    font-size: 20px;
    font-weight: bolder;
    color: #b41313;
  }
  
  .wizard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%; /* Full width of the screen */
    padding: 0 20px; /* Optional padding for better alignment */
  }
  
  .wizard-heading-line {
    width: 80%; /* Adjust the width as needed */
    margin: 10px auto; /* Center the line and add some space around it */
    border: none;
    height: 0.5px; /* Thickness of the line */
    background-color: #393939; /* Line color */
  }
  
  .wizard-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-radius: 10px;
    width: 100%; /* Adjust to full width */
    max-width: 1200px; /* Optional max width */
  }
  
  .wizard-question-block p {
    margin-bottom: 30px; /* Add padding between the question and the options */
  }
  
  .wizard-options {
    display: flex; /* Use flexbox for row layout */
    flex-wrap: wrap; /* Allow wrapping if there are too many options */
    justify-content: center; /* Space out options evenly */
    gap: 20px; /* Space between options */
    width: 100%; /* Full width of the screen */
  }
  
  .wizard-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    justify-content: space-evenly;
    border: 1px solid #dbd7d7; /* Corrected border property */
    background-color: #efefeffa; /* or rgba(239, 239, 239, 0.98) */
    border-radius: 10px;
    flex: 1; /* Grow to fill available space */
    min-width: 200px; /* Minimum width of each option */
    max-width: 300px; /* Added max-width for better scaling */
  }
  
  .wizard-option:hover,
  .wizard-option.selected {
    transform: translate(0, -1.5px);
    box-shadow: 0 6px 6px -2px rgba(213, 211, 211, 0.55);
    border: 1px solid #007bff; /* Add border to highlight selected option */
    background-color: #e0e7ff; /* Change background color for selected option */
  }
  
  .wizard-option-button {
    border-radius: 6px;
  }
  
  .wizard-button {
    color: #4e4b4b;
    border: 1px solid #d1d1d2;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .wizard-questions {
    font-size: 20px;
  }
  
  .wizard-question {
    font-size: 20px;
  }
  
  .wizard-button:hover {
    background-color: #043c6ac5;
    color: #ffffff;
  }
  
  .wizard-navigation-buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    width: 100%; /* Full width of the screen */
    justify-content: center;
  }
  
  .wizard-navigation-buttons button {
    background-color: #00438a;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .wizard-navigation-buttons button:hover {
    background-color: #0b4d93eb;
  }
  
  .wizard-navigation-buttons button:disabled {
    background-color: #494747;
    cursor: not-allowed;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .wizard-step {
      width: 100%;
      max-width: 600px; /* Adjusted for smaller screens */
      padding: 10px;
    }
  
    .wizard-option {
      min-width: 150px; /* Adjusted min-width for smaller screens */
    }
  
    .wizard-button {
      font-size: 14px; /* Slightly smaller font size for buttons */
    }
  
    .wizard-questions {
      font-size: 18px;
    }
  
    .wizard-question {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .wizard-step {
      max-width: 100%;
      padding: 10px;
    }
  
    .wizard-option {
      min-width: 100px; /* Adjusted min-width for very small screens */
    }
  
    .wizard-button {
      font-size: 12px; /* Smaller font size for buttons */
      padding: 8px 16px; /* Smaller padding */
    }
  
    .wizard-questions {
      font-size: 16px;
    }
  
    .wizard-question {
      font-size: 16px;
    }
  }
  