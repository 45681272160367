.billing-header-container {
    
    /* margin: 30px;
    padding-bottom: 4rem ; */
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 4rem ;
    margin:0px 30px;
    
  }

  .disabledclass {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.8;
    background-color: #eee;
}


  .centered {
    display: inline-block;
    /* border: 1px solid black; */
    padding: 1rem 1rem;
   
  }
  .table-head-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .table-head-text{
    /* margin: 1em; */
      display: flex;
      font-size: 24px;
      font-weight: 700;
      color:#575757;
      justify-content: space-between;

      /* padding-bottom: 4rem ; */
      /* margin-left: 2em; */
      width: 75%;
    }
  .in{
    display: inline-block;

 
  }
  /* .put{
    /* padding-left: 37em; */
  
  .billing-container{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 350px;
  }
  .card{
    width: 75%;
   
  }
  .billing-table{
    margin:0px 30px !important;

  }
 
  .form-control{
    width: 5%;
  }
  .left{
   /* text-align: right; */
   display: flex;
   justify-content: flex-end;
  }
  .view-btn{
    font-size: 1em;
  line-height: 1.1em;
  color: #fff;
  border: 1px solid #333;
  padding: 8px;
  background-color: #0a1352;
  text-transform: capitalize;

  justify-content: center !important;
  border-radius: 5px;
  }